import React, { useState } from 'react';
import withPodcast from '../utils/withPodcast';
import {
  ExtendedFirebaseInstance,
  ExtendedFirestoreInstance,
} from 'react-redux-firebase';
import { ManagePodcastTab, ModalType, Podcast } from '../types';
import { TopNav } from '../components/top-nav/TopNav';
import { ManagePodcast } from '../components/manage-podcast/ManagePodcast';
import s from './ManagePodcastUsers.module.css';
import { Button } from '../components/button/Button';
import { PageContainer } from '../components/page-container/PageContainer';
import { TextInput } from '../components/text-input/TextInput';
import { ManageSubscriptionsList } from '../components/manage-subscriptions-list/ManageSubscriptionsList';
import { Spinner } from '../components/spinner/Spinner';
import { NoItems } from '../components/no-items/NoItems';
import { validateEmail } from '../utils/utilities';
import { useDispatch } from 'react-redux';
import { showModal } from '../redux/actions/modal';
import { useSubscriptionsSearch } from '../queries/subscriptions';

const PodcastUsersSearchResults: React.FC<{
  podcast: Podcast;
  searchQuery: string;
}> = ({ searchQuery, podcast }) => {
  const { data: subscriptions, isLoading } = useSubscriptionsSearch({
    podcastId: podcast.id,
    search: searchQuery,
  });
  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  return (
    <>
      {!!subscriptions?.length && (
        <ManageSubscriptionsList subscriptions={subscriptions} />
      )}
      {!subscriptions?.length && (
        <NoItems message={'Engar áskriftir fundust.'} />
      )}
    </>
  );
};

const ManagePodcastUsers: React.FC<any> = (props) => {
  const {
    podcast,
  }: {
    firebase: ExtendedFirebaseInstance;
    firestore: ExtendedFirestoreInstance;
    podcast: Podcast;
    profile: any;
  } = props;
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const searchForSubscription = () => {
    setSearchQuery(search);
  };

  return (
    <PageContainer>
      <TopNav />
      <ManagePodcast context={ManagePodcastTab.USERS} podcast={podcast}>
        <div className={s.container}>
          <>
            <div className={s.button}>
              {!!podcast.subscriptionPlans?.length ? (
                <>
                  <Button to={`/podcasts/subscriptions/${podcast.id}/new`}>
                    Stofna áskrift
                  </Button>
                  <Button
                    onClick={() => {
                      dispatch(
                        showModal(ModalType.RANDOM_SUBSCRIPTION, {
                          podcastId: podcast.id,
                        }),
                      );
                    }}
                    className={'black'}
                  >
                    Velja af handahófi
                  </Button>
                </>
              ) : (
                <Button to={`/podcasts/edit/${podcast.id}`}>
                  Stofna áskriftarleiðir
                </Button>
              )}
            </div>
            <div className={s.search}>
              <div className={s.searchInput}>
                <TextInput
                  id={'search-input'}
                  autofocus={true}
                  value={search}
                  label={'Leita að áskrift'}
                  onEnter={() => {
                    searchForSubscription();
                  }}
                  placeholder={'Skrifaðu netfangið hér...'}
                  onChange={(val) => setSearch(val)}
                />
              </div>
              <div className={s.searchBtn}>
                <Button
                  disabled={!search}
                  className={'green'}
                  onClick={() => searchForSubscription()}
                >
                  Leita
                </Button>
              </div>
            </div>
            <div className={s.content}>
              {searchQuery && (
                <PodcastUsersSearchResults
                  searchQuery={searchQuery}
                  podcast={podcast}
                />
              )}
            </div>
          </>
        </div>
      </ManagePodcast>
    </PageContainer>
  );
};

export default withPodcast(ManagePodcastUsers);
