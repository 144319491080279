import React from 'react';
import cn from 'classnames';
import s from './EventButton.module.css';

export const EventButton = ({ url, text }: { url: string; text: string }) => {
  return (
    <div>
      <a
        href={url}
        className={cn(
          s.eventBtn,
          'tw-mx-auto',
          'tw-bg-red-600',
          'tw-text-white',
          'tw-block',
          'tw-text-center',
          'tw-p-3',
          'tw-min-h-[80px]',
          'tw-flex',
          'tw-items-center',
          'tw-justify-center',
          'hover:tw-bg-red-600',
          'tw-border',
          'tw-border-red-600',
          'tw-font-700',
          'tw-uppercase',
        )}
      >
        <span className="tw-text-white">{text}</span>
      </a>
    </div>
  );
};
