import React, { useEffect, useState } from 'react';
import { error, success } from '../utils/Notifications';
import { Redirect } from 'react-router';
import { useFirestore } from 'react-redux-firebase';
import SubscriptionBusiness from '../business/SubscriptionBusiness';
import withPodcast from '../utils/withPodcast';
import { SubscriptionForm } from '../components/subscription-form/SubscriptionForm';
import { Podcast, SubscriptionPlan } from '../types';
import { PageContainer } from '../components/page-container/PageContainer';
import { useCreateManualSubscription } from '../queries/subscriptions';

const ManagePodcastNewSubscription: React.FC<any> = (props) => {
  const [redirect, setRedirect] = useState<string>('');
  const { podcast }: { podcast: Podcast } = props;
  const {
    mutate,
    isSuccess,
    isPending,
    isError,
    error: err,
  } = useCreateManualSubscription();
  useEffect(() => {
    if (isSuccess) {
      success('Áskriftin hefur verið stofnuð.');
      setRedirect('/podcasts/users/' + podcast.id);
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isError) {
      error(err.message || 'Ekki tókst að stofna áskrift');
    }
  }, [isError]);

  return (
    <PageContainer>
      {podcast.subscriptionPlans?.length && (
        <SubscriptionForm
          title={'Ný áskrift'}
          save={(email, plan) =>
            mutate({ podcastId: podcast.id!, email, planId: plan.id })
          }
          backRoute={'/podcasts/users/' + podcast.id}
          plans={podcast.subscriptionPlans}
          isLoading={isPending}
        />
      )}
      {redirect && <Redirect to={redirect} />}
    </PageContainer>
  );
};

export default withPodcast(ManagePodcastNewSubscription);
