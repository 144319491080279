import { useFormContext } from 'react-hook-form';
import { ErrorLabel } from './ErrorLabel';
import cn from 'classnames';
import s from '../toggle-podcast/TogglePodcast.module.css';
import Switch from 'react-switch';
import React, { ComponentProps } from 'react';

export const FormToggle = ({
  name,
  label,
}: {
  name: string;
  label?: string;
}) => {
  const { watch, setValue } = useFormContext();
  const checked = !!watch(name);
  return (
    <div className={'tw-flex tw-gap-2 tw-items-center'}>
      {label && (
        <div>
          <label htmlFor="material-switch" className={'tw-font-700'}>{label}</label>
        </div>
      )}
      <Switch
        checked={checked}
        onChange={(val) => {
          setValue(name, val);
        }}
        onColor="#7857C8"
        onHandleColor="#211148"
        handleDiameter={30}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={20}
        width={48}
        className="react-switch"
        id="material-switch"
      />
    </div>
  );
};
