import React from 'react';
import s from './ManageSubscriptionsList.module.css';
import moment from 'moment';
import { ModalType, Subscription, SubscriptionPlan } from '../../types';
import { SimpleTable } from '../simple-table/SimpleTable';
import { ContextMenu } from '../context-menu/ContextMenu';
import { useDispatch } from 'react-redux';
import { showModal } from '../../redux/actions/modal';
import { useFirestore } from 'react-redux-firebase';
import SubscriptionBusiness from '../../business/SubscriptionBusiness';
import { useTypedSelector } from '../../redux/reducers';
import z from 'zod';
import { podcastSubscriptionSchema } from '../../schemas/subscriptions';

type Props = {
  subscriptions: z.infer<typeof podcastSubscriptionSchema>[];
};

export const ManageSubscriptionsList: React.FC<Props> = ({ subscriptions }) => {
  const dispatch = useDispatch();
  const profile = useTypedSelector((state) => state.firebase.profile);
  const firestore = useFirestore();
  const subscriptionBusiness = new SubscriptionBusiness(firestore);
  const removeTimeLimit = (
    subscriptionId: string,
    subscriptionPlan: SubscriptionPlan,
  ) => {
    subscriptionBusiness.removeTimeLimit(subscriptionId, subscriptionPlan);
  };
  return (
    <div className={s.container}>
      <SimpleTable
        cols={[
          'Dagsetning',
          'Gildir til',
          'Netfang',
          'Tilvísun',
          'Tímamörk',
          '',
        ]}
        rows={subscriptions.map((s) => [
          moment(s.createdAt).format('DD. MMM YYYY HH:mm'),
          moment(s.endsAt).format('DD. MMM YYYY HH:mm'),
          s.userEmail,
          s.reference,
          s.subscriptionPlan.timeLimit
            ? moment(s.createdAt)
                .subtract(
                  s.subscriptionPlan.timeLimit.value,
                  s.subscriptionPlan.timeLimit.unit,
                )
                .format('DD. MMM YYYY')
            : 'Engin',
          s.subscriptionPlan.timeLimit && profile.isAdmin ? (
            <div style={{ textAlign: 'right', paddingTop: '8px' }}>
              <ContextMenu minWidth={190}>
                <div
                  onClick={() =>
                    dispatch(
                      showModal(ModalType.CONFIRM_MODAL, {
                        title: 'Fjarlægja tímamörk',
                        body: 'Viltu fjarlægja tímamörk?',
                        actionText: 'Já',
                        action: () =>
                          removeTimeLimit(s.id as string, s.subscriptionPlan),
                      }),
                    )
                  }
                >
                  Fjarlægja tímamörk
                </div>
              </ContextMenu>
            </div>
          ) : (
            ''
          ),
        ])}
      />
    </div>
  );
};
