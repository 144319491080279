import { useMutation } from '@tanstack/react-query';
import z from 'zod';
import { call } from '../utils/ApiManager';
import { eventButtonFormSchema } from '../schemas/podcast-settings.schema';

export const useUpdateEventButton = (podcastId?: string) => {
  return useMutation({
    mutationFn: async (data: z.infer<typeof eventButtonFormSchema>) =>
      call(`/podcasts/${podcastId}/event-button`, false, null, 'PATCH')(data),
  });
};
