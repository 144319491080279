import { useMutation, useQuery } from '@tanstack/react-query';
import { call } from '../utils/ApiManager';
import { zodParse } from '../utils/zod-utils';
import {
  createManualSubscriptionRequest,
  podcastSubscriptionSchema,
} from '../schemas/subscriptions';
import z from 'zod';
import { eventButtonFormSchema } from '../schemas/podcast-settings.schema';

export const useSubscriptionsSearch = ({
  podcastId,
  search,
}: {
  podcastId?: string;
  search: string;
}) => {
  return useQuery({
    queryKey: ['subscriptions', 'list', podcastId, search],
    queryFn: async () =>
      zodParse(
        podcastSubscriptionSchema.array(),
        await call(
          `/podcasts/${podcastId}/subscriptions?search=${encodeURIComponent(search)}`,
          false,
          null,
          'GET',
        )(),
      ),
    enabled: !!podcastId,
    retry: false,
  });
};

export const useCreateManualSubscription = () => {
  return useMutation({
    mutationFn: async (data: z.infer<typeof createManualSubscriptionRequest>) =>
      call(`/manual-subscriptions`, false, null, 'POST')(data),
  });
};
