import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { TopNav } from '../components/top-nav/TopNav';
import s from './PodcastDetail.module.css';
import { PodcastDetailInfo } from '../components/podcast-detail-info/PodcastDetailInfo';
import { PodcastPlaylist } from '../components/podcast-playlist/PodcastPlaylist';
import { call } from '../utils/ApiManager';
import { Episode, Podcast } from '../types';
import { Spinner } from '../components/spinner/Spinner';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useTypedSelector } from '../redux/reducers';
import classNames from 'classnames';
import { Center } from '../components/position/Center';
import { PodcastFeed } from '../components/podcast-feed/PodcastFeed';
import { PostToFeed } from '../components/post-to-feed/PostToFeed';
import { LockedFeed } from '../components/locked-feed/LockedFeed';
import { PageContainer } from '../components/page-container/PageContainer';
import moment from 'moment';
import { RemoveTimeLimitButton } from '../components/remove-timelimit-button/RemoveTimeLimitButton';
import clientConfig from '../client-config';
import { PendingFeeds } from '../components/pending-feeds/PendingFeeds';
import { RssContainer } from '../components/podcast-description/RssContainer';
import { EventButton } from "../components/event-button/EventButton";

const PodcastDetail: React.FC<any> = (props) => {
  const [episodes, setEpisodes] = useState<Episode[]>([]);
  const [openEpisodes, setOpenEpisodes] = useState<Episode[]>([]);
  const [uploadedOpenEpisodes, setUploadedOpenEpisodes] = useState<Episode[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasSubscription, setHasSubscription] = useState<boolean>(false);
  const [timeLimit, setTimeLimit] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState<string>();
  const [timeLimitResetPrice, setTimeLimitResetPrice] = useState<number>(0);
  const auth = useTypedSelector((state) => state.firebase.auth);
  const {
    match: { params },
  } = props;
  const { slug } = params;
  useFirestoreConnect({
    collection: 'podcasts',
    where: [
      ['slug', '==', slug],
      ['status', '==', 'PUBLISHED'],
      ['client', '==', clientConfig.name],
    ],
    storeAs: 'podcast-' + slug,
  });

  const data = useTypedSelector(
    (state) => state.firestore.ordered['podcast-' + slug],
  );
  const profile = useTypedSelector((state) => state.firebase.profile);
  const podcast: Podcast = data ? data[0] : null;
  const podcastId = podcast?.id || '';
  const loadData = async () => {
    if (!podcast?.id) {
      return;
    }
    setIsLoading(true);
    const episodes = await call(`/podcasts/${podcast.id}/episodes`, true)();
    setEpisodes(episodes.episodes);
    setOpenEpisodes(episodes.openEpisodes);
    setHasSubscription(episodes.hasSubscription);
    setUploadedOpenEpisodes(episodes.uploadedOpenEpisodes);
    setIsLoading(false);
    if (episodes.timeLimit) {
      setTimeLimit(episodes.timeLimit);
      setTimeLimitResetPrice(episodes.timeLimitResetPrice);
    } else {
      setTimeLimit(null);
      setTimeLimitResetPrice(0);
    }
    if (episodes.hasSubscription) {
      setSubscriptionId(episodes.subscriptionId);
    }
  };
  useEffect(() => {
    if (auth.isLoaded && isLoaded(podcast)) {
      loadData();
    }
  }, [auth.isLoaded, podcast?.id]);

  const getHasSubscriptionText = () => {
    if (timeLimit) {
      return (
        <>
          {'Þú hefur aðgang að læstum þáttum aftur að ' +
            moment(timeLimit).format('DD. MMM YYYY') +
            '.'}
          <br />
          {timeLimitResetPrice > 0 && (
            <RemoveTimeLimitButton
              reloadData={loadData}
              resetPrice={timeLimitResetPrice}
              subscriptionId={subscriptionId as string}
            />
          )}
        </>
      );
    }
    return 'Þú hefur aðgang að læstum þáttum.';
  };
  return (
    <PageContainer>
      {hasSubscription && podcast.eventButton?.visible && (
        <EventButton
          url={podcast.eventButton.url}
          text={podcast.eventButton.text}
        />
      )}
      <TopNav />
      {!isLoading ? (
        <Container>
          {podcast && podcast.id === podcastId && (
            <div
              className={classNames({
                [s.container]: true,
                [s.noEpisodes]: !episodes?.length && !openEpisodes?.length,
              })}
            >
              <div className={classNames({ [s.left]: true })}>
                <div
                  className={classNames({
                    [s.hideMobile]:
                      !!episodes?.length || !!openEpisodes?.length,
                  })}
                >
                  <PodcastDetailInfo
                    hasSubscription={hasSubscription}
                    podcast={podcast}
                    isOwner={podcast.userId === auth.uid}
                    isAdmin={profile.isAdmin}
                  />
                  {hasSubscription && !!podcast.subscriptionPlans?.length && <RssContainer />}
                </div>
                {!hasSubscription &&
                  (podcast.subscriptionPlans?.length || 0) > 0 && (
                    <LockedFeed count={podcast.feedCount || 0} />
                  )}
                {(podcast.userId === auth.uid || profile.isAdmin) && (
                  <>
                    <PostToFeed podcastId={podcastId} userId={auth.uid} />
                    <PendingFeeds podcastId={podcastId} />
                  </>
                )}
                {(!auth.isEmpty && hasSubscription) ||
                !podcast.subscriptionPlans?.length ? (
                  <PodcastFeed slug={slug} podcastId={podcastId} />
                ) : null}
              </div>
              {(!!episodes?.length || !!openEpisodes?.length) && (
                <div className={s.right}>
                  <div className={s.hideDesktop}>
                    <PodcastDetailInfo
                      hasSubscription={hasSubscription}
                      podcast={podcast}
                      isOwner={podcast.userId === auth.uid}
                      isAdmin={profile.isAdmin}
                    />
                    {hasSubscription && !!podcast.subscriptionPlans?.length && <RssContainer />}
                  </div>
                  <PodcastPlaylist
                    podcast={podcast}
                    title={'Læstir þættir'}
                    description={
                      hasSubscription
                        ? getHasSubscriptionText()
                        : 'Þú verður að kaupa aðgang til að spila læsta þætti.'
                    }
                    episodes={episodes}
                  />
                  <div style={{ marginTop: '20px' }}>
                    {!!openEpisodes.length && (
                      <PodcastPlaylist
                        podcast={podcast}
                        title={'Opnir þættir'}
                        episodes={openEpisodes.filter(
                          (e) => e.podcastId === podcastId,
                        )}
                      />
                    )}
                  </div>
                  {!!uploadedOpenEpisodes.length && (
                    <div style={{ marginTop: '20px' }}>
                      <PodcastPlaylist
                        podcast={podcast}
                        title={'Opið efni'}
                        episodes={uploadedOpenEpisodes.filter(
                          (e) => e.podcastId === podcastId,
                        )}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </Container>
      ) : (
        <Center>
          <Spinner />
        </Center>
      )}
    </PageContainer>
  );
};

export default PodcastDetail;
